import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  getLocation(): Promise<GeolocationCoordinates> {
    return new Promise(resolve => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => resolve(position.coords),
          () => resolve(<GeolocationCoordinates>{})
        );
      } else {
        resolve(<GeolocationCoordinates>{});
      }
    });
  }
}
